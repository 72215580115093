export enum JiraProjectKeys {
  DS = "DS",
  GTR = "GTR",
  DE = "DE",
  BI = "BI",
  GE = "GE",
  FDDS = "FDDS",
  L10N = "L10N",
}

export const JiraEpicLinks = {
  [JiraProjectKeys.DS]: {
    DS5092: "DS-5092",
  },
  [JiraProjectKeys.DE]: {
    DE400: "DE-400",
    DE1279: "DE-1279",
    DE1496: "DE-1496",
  },
  [JiraProjectKeys.BI]: {
    BI84: "BI-84",
    BI1089: "BI-1089",
  },
  [JiraProjectKeys.GTR]: {
    GTR3: "GTR-3",
  },
  [JiraProjectKeys.GE]: {
    GE160: "GE-160",
    GE817: "GE-817",
    GE1163: "GE-1163",
    GE2339: "GE-2339",
    GE2340: "GE-2340",
    GE4173: "GE-4173",
  },
  [JiraProjectKeys.FDDS]: {
    FDDS666: "FDDS-666",
    FDDS667: "FDDS-667",
    FDDS674: "FDDS-674",
    FDDS683: "FDDS-683",
    FDDS684: "FDDS-684",
    FDDS685: "FDDS-685",
    FDDS846: "FDDS-846",
    FDDS1000: "FDDS-1000",
    FDDS1015: "FDDS-1015", // For Non-GSA DS requests
    FDDS1557: "FDDS-1557", // EBR 2.0 Load-In Requests
    FDDS1558: "FDDS-1558", // Customer Analytics Data Extract Requests
  },
  [JiraProjectKeys.L10N]: {
    L10N18: "L10N-18",
    L10N19: "L10N-19",
    L10N4: "L10N-4",
  },
};

export enum JiraLabel {
  Analytics_Support = "Analytics-Support",
  Data_Support = "Data-Support",
  DE_Support = "DE-Support",
  BI_Support = "BI-Support",
  GE = "GE",
  Customer_request = "customer-request",
  CR_data = "cr-data",
  CR_insight = "cr-insight",
  CR_stratcon = "cr-stratcon",
  Analytics_request = "analytics-request",
  Data_extract_request = "data-extract-request",
  Data_Engineering = "data-engineering",
  DER_custom_event = "der-custom-event-retention",
  DER_messaging = "der-messaging",
  DER_opt_in_rates = "der-opt-in-rates",
  DER_anon_users = "der-anon-users",
  DER_CER = "der-customer-engagement-review",
  DER_EBR_2 = "der-ebr-2.0",
  DER_SMS_Optin = "der-sms-optin",
  Tools_access_request = "tools-access-request",
  Tools_bug_request = "tools-bug-request",
  Tools_feature_request = "tools-feature-request",
  Automation_request = "automation-request",
  New_product_request = "new-product-request",
  Product_feedback_request = "product-feedback-request",
  Bug_request = "bug-request",
  PLG = "PLG-to-GTM-automation",
  Slidebot = "slide-request",
  Data_Services = "data-services",
  L10N = "L10N",
  Product_analytics = "product-analytics-request",
  New_Data_Source = "new-data-source",
  Sandbox_Dashboard = "sandbox-dashboard",
}

export type JiraConfig = {
  jiraProjectKey: JiraProjectKeys;
  epicLink: string;
  labels: JiraLabel[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const jiraRoutes: Record<string, any> = {
  "Analytics and Reporting for Customers": {
    "analysis::Customer Data Retrieval [CS VPs only]": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.FDDS,
        epicLink: JiraEpicLinks.FDDS.FDDS1015,
        labels: [
          JiraLabel.Analytics_Support,
          JiraLabel.Customer_request,
          JiraLabel.CR_data,
        ],
      } as JiraConfig,
    },
    "analysis::Customer Insights Exploration [CS VPs only]": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.FDDS,
        epicLink: JiraEpicLinks.FDDS.FDDS1015,
        labels: [
          JiraLabel.Analytics_Support,
          JiraLabel.Customer_request,
          JiraLabel.CR_insight,
        ],
      } as JiraConfig,
    },
    "analysis::Strategic Consulting Request": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.FDDS,
        epicLink: JiraEpicLinks.FDDS.FDDS1015,
        labels: [
          JiraLabel.Analytics_Support,
          JiraLabel.Customer_request,
          JiraLabel.CR_stratcon,
        ],
      } as JiraConfig,
    },
  },
  "Analytics and Reporting for Internal Purposes": {
    jiraConfig: {
      jiraProjectKey: JiraProjectKeys.GTR,
      epicLink: JiraEpicLinks.GTR.GTR3,
      labels: [JiraLabel.Data_Support, JiraLabel.Analytics_request],
    } as JiraConfig,
  },
  "Data Extract Request": {
    "typeOfData::Custom Event A/B Retention": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.DS,
        epicLink: JiraEpicLinks.DS.DS5092,
        labels: [
          JiraLabel.Analytics_Support,
          JiraLabel.Data_extract_request,
          JiraLabel.DER_custom_event,
        ],
      } as JiraConfig,
    },
    "typeOfData::Messaging Frequency": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.DS,
        epicLink: JiraEpicLinks.DS.DS5092,
        labels: [
          JiraLabel.Analytics_Support,
          JiraLabel.Data_extract_request,
          JiraLabel.DER_messaging,
        ],
      } as JiraConfig,
    },
    "typeOfData::Email and Push Opt-In Rates": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.DS,
        epicLink: JiraEpicLinks.DS.DS5092,
        labels: [
          JiraLabel.Analytics_Support,
          JiraLabel.Data_extract_request,
          JiraLabel.DER_opt_in_rates,
        ],
      } as JiraConfig,
    },
    "typeOfData::Anonymous Users": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.DS,
        epicLink: JiraEpicLinks.DS.DS5092,
        labels: [
          JiraLabel.Analytics_Support,
          JiraLabel.Data_extract_request,
          JiraLabel.DER_anon_users,
        ],
      } as JiraConfig,
    },
    "typeOfData::Customer Engagement Review": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.DS,
        epicLink: JiraEpicLinks.DS.DS5092,
        labels: [
          JiraLabel.Analytics_Support,
          JiraLabel.Data_extract_request,
          JiraLabel.DER_CER,
        ],
      } as JiraConfig,
    },
    "typeOfData::SMS Opt-In Rates": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.DS,
        epicLink: JiraEpicLinks.DS.DS5092,
        labels: [
          JiraLabel.Analytics_Support,
          JiraLabel.Data_extract_request,
          JiraLabel.DER_SMS_Optin,
        ],
      } as JiraConfig,
    },
    "typeOfData::EBR 2.0 Request Form": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.FDDS,
        epicLink: JiraEpicLinks.FDDS.FDDS1557,
        labels: [
          JiraLabel.Analytics_Support,
          JiraLabel.Data_extract_request,
          JiraLabel.DER_EBR_2,
        ],
      } as JiraConfig,
    },
  },
  "BI Support": {
    "requestType::Access & Permissions (Looker)": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.BI,
        epicLink: JiraEpicLinks.BI.BI1089,
        labels: [JiraLabel.BI_Support, JiraLabel.Tools_access_request],
      } as JiraConfig,
    },
    "requestType::Report a Bug": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.BI,
        epicLink: JiraEpicLinks.BI.BI84,
        labels: [JiraLabel.BI_Support, JiraLabel.Tools_bug_request],
      } as JiraConfig,
    },
    "requestType::Feature Request (Dashboard level change)": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.BI,
        epicLink: JiraEpicLinks.BI.BI84,
        labels: [JiraLabel.BI_Support, JiraLabel.Tools_feature_request],
      } as JiraConfig,
    },
  },
  "Data Services": {
    "companyCategory::SMB": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.FDDS,
        epicLink: JiraEpicLinks.FDDS.FDDS1015,
        labels: [JiraLabel.Automation_request, JiraLabel.Data_Services],
      } as JiraConfig,
    },
    "companyCategory::Scale": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.FDDS,
        epicLink: JiraEpicLinks.FDDS.FDDS1015,
        labels: [JiraLabel.Automation_request, JiraLabel.Data_Services],
      } as JiraConfig,
    },
    "companyCategory::Enterprise": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.FDDS,
        epicLink: JiraEpicLinks.FDDS.FDDS1015,
        labels: [JiraLabel.Automation_request, JiraLabel.Data_Services],
      } as JiraConfig,
    },
    "companyCategory::GSA": {
      "gsaCustomer::Disney": {
        jiraConfig: {
          jiraProjectKey: JiraProjectKeys.FDDS,
          epicLink: JiraEpicLinks.FDDS.FDDS683,
          labels: [JiraLabel.Automation_request, JiraLabel.Data_Services],
        } as JiraConfig,
      },
      "gsaCustomer::Estee Lauder": {
        jiraConfig: {
          jiraProjectKey: JiraProjectKeys.FDDS,
          epicLink: JiraEpicLinks.FDDS.FDDS1000,
          labels: [JiraLabel.Automation_request, JiraLabel.Data_Services],
        } as JiraConfig,
      },
      "gsaCustomer::Procter & Gamble": {
        jiraConfig: {
          jiraProjectKey: JiraProjectKeys.FDDS,
          epicLink: JiraEpicLinks.FDDS.FDDS666,
          labels: [JiraLabel.Automation_request, JiraLabel.Data_Services],
        } as JiraConfig,
      },
      "gsaCustomer::RBI": {
        jiraConfig: {
          jiraProjectKey: JiraProjectKeys.FDDS,
          epicLink: JiraEpicLinks.FDDS.FDDS685,
          labels: [JiraLabel.Automation_request, JiraLabel.Data_Services],
        } as JiraConfig,
      },
      "gsaCustomer::Viacom": {
        jiraConfig: {
          jiraProjectKey: JiraProjectKeys.FDDS,
          epicLink: JiraEpicLinks.FDDS.FDDS667,
          labels: [JiraLabel.Automation_request, JiraLabel.Data_Services],
        } as JiraConfig,
      },
      "gsaCustomer::Warner Media": {
        jiraConfig: {
          jiraProjectKey: JiraProjectKeys.FDDS,
          epicLink: JiraEpicLinks.FDDS.FDDS684,
          labels: [JiraLabel.Automation_request, JiraLabel.Data_Services],
        } as JiraConfig,
      },
    },
  },
  "Growth Engineering": {
    "describeRequest::Workflow Automation": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.GE,
        epicLink: JiraEpicLinks.GE.GE160,
        labels: [JiraLabel.GE, JiraLabel.Automation_request],
      } as JiraConfig,
    },
    "describeRequest::Product Requests/Feedback": {
      "requestCategory::Growth Engineering Request": {
        jiraConfig: {
          jiraProjectKey: JiraProjectKeys.GE,
          epicLink: JiraEpicLinks.GE.GE817,
          labels: [JiraLabel.GE, JiraLabel.Product_feedback_request],
        },
      },
      "requestCategory::Web Engineering Request": {
        jiraConfig: {
          jiraProjectKey: JiraProjectKeys.GE,
          epicLink: JiraEpicLinks.GE.GE4173,
          labels: [JiraLabel.GE, JiraLabel.Product_feedback_request],
        } as JiraConfig,
      },
    },
    "describeRequest::Bug Report": {
      "requestCategory::General": {
        jiraConfig: {
          jiraProjectKey: JiraProjectKeys.GE,
          epicLink: JiraEpicLinks.GE.GE817,
          labels: [JiraLabel.GE, JiraLabel.Bug_request],
        } as JiraConfig,
      },
      "requestCategory::Braze.com": {
        jiraConfig: {
          jiraProjectKey: JiraProjectKeys.GE,
          epicLink: JiraEpicLinks.GE.GE4173,
          labels: [JiraLabel.GE, JiraLabel.Bug_request],
        } as JiraConfig,
      },
    },
  },
  Slidebot: {
    jiraConfig: {
      jiraProjectKey: JiraProjectKeys.GE,
      epicLink: JiraEpicLinks.GE.GE1163,
      labels: [JiraLabel.GE, JiraLabel.Slidebot],
    } as JiraConfig,
  },
  "PLG to GTM Automation": {
    jiraConfig: {
      jiraProjectKey: JiraProjectKeys.GE,
      epicLink: JiraEpicLinks.GE.GE2339,
      labels: [JiraLabel.GE, JiraLabel.PLG],
    },
  },
  "Sandbox Dashboard": {
    jiraConfig: {
      jiraProjectKey: JiraProjectKeys.GE,
      epicLink: JiraEpicLinks.GE.GE2340,
      labels: [JiraLabel.GE, JiraLabel.Sandbox_Dashboard],
    } as JiraConfig,
  },
  "Product Analytics": {
    jiraConfig: {
      jiraProjectKey: JiraProjectKeys.FDDS,
      epicLink: JiraEpicLinks.FDDS.FDDS846,
      labels: [JiraLabel.Product_analytics],
    } as JiraConfig,
  },
  "New Data Source": {
    jiraConfig: {
      jiraProjectKey: JiraProjectKeys.DE,
      epicLink: JiraEpicLinks.DE.DE1496,
      labels: [JiraLabel.DE_Support, JiraLabel.New_Data_Source],
    } as JiraConfig,
  },
  "Data Engineering Request": {
    "requestType::Access & Permissions (Snowflake/dbt/Fivetran/Airflow)": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.DE,
        epicLink: JiraEpicLinks.DE.DE1279,
        labels: [JiraLabel.Data_Engineering, JiraLabel.Tools_access_request],
      } as JiraConfig,
    },
    "requestType::Feature Request (Column level change)": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.DE,
        epicLink: JiraEpicLinks.DE.DE400,
        labels: [JiraLabel.Data_Engineering, JiraLabel.Tools_feature_request],
      } as JiraConfig,
    },
    "requestType::Report a Bug": {
      jiraConfig: {
        jiraProjectKey: JiraProjectKeys.DE,
        epicLink: JiraEpicLinks.DE.DE400,
        labels: [JiraLabel.Data_Engineering, JiraLabel.Tools_bug_request],
      } as JiraConfig,
    },
  },
};

export const getJiraConfig = (
  requestTitle: string,
  stateKeys: object
): JiraConfig | null => {
  const states = Object.entries(stateKeys);
  const j = states.reduce((a, c) => {
    const [key, value] = c;
    const field = `${key}::${value}`;
    return field in a ? a[field] : a;
  }, jiraRoutes[requestTitle]);
  return j?.length ? null : j?.jiraConfig;
};
