import styled from "styled-components";
import { Button, Flex, Label, PanelBody } from "@braze/beacon-core";

export const HeadingLabel = styled(Label)`
  font-size: 24px;
  color: #0dafc5;
  margin-bottom: 1.5rem;
`;

export const HighlightedText = styled.span`
  font-weight: bold;
`;

export const NewRequestButton = styled(Button)`
  margin-top: 1rem;
  width: 300px;
  justify-content: center;
`;

export const StyledFlex = styled(Flex)`
  margin: 1rem 0;
`;

export const StyledLink = styled.a`
  color: #008294;
`;

export const StyledOL = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledPanelBody = styled(PanelBody)`
  border: none;
  box-shadow: 0px 4px 10px rgba(69, 84, 94, 0.1);
  padding: 2rem 4rem;
  border-top: 1px solid #8080802b;
  max-width: 600px;
`;

export const TorchieImg = styled.img`
  height: 225px;
  width: 114px;
`;
