import { useCreateIssue } from "src/hooks/useCreateIssue";
import { useForm } from "src/hooks/useForm";
import { getJiraConfig } from "src/pages/growth/jiraRouting";
import {
  Checkbox,
  FieldLabel,
  Flex,
  Input,
  Textarea,
  makeToast,
} from "@braze/beacon-core";
import { NavigateBackButton } from "src/components/NavigateBackButton";
import {
  FormDetails,
  FormHeading,
  FormPanel,
  FormPanelBody,
  InputLabel,
  RequestForm,
  SubText,
  SubmitButton,
  TopMargin,
} from "src/components/commonStyles";
import { requiredLabel } from "src/components/required";
import { createJiraIssueReq } from "./utils/jiraUtils";
import { DataTypeOptions, WorkImpactOptions } from "./data";
import { Select } from "@braze/beacon-lab-select";
import { ThankYou } from "./../../pages/thank-you";

export type FormField = {
  title: string;
  isConfirmed: boolean;
  dataType: string;
  requestSummary: string;
  workImpact: string;
  stakeholders: string;
};

export const NewDataSource = () => {
  const [createJiraIssue, createJiraIssueRes] = useCreateIssue();

  const { formState, setFormFieldValue } = useForm<FormField>({
    title: "",
    isConfirmed: false,
    dataType: "",
    requestSummary: "",
    workImpact: "",
    stakeholders: "",
  });

  const {
    title,
    isConfirmed,
    dataType,
    requestSummary,
    workImpact,
    stakeholders,
  } = formState;
  // Disable submit button if following aren't populated
  const submitDisabled =
    !(
      !!title &&
      !!isConfirmed &&
      !!dataType &&
      !!requestSummary &&
      !!workImpact &&
      !!stakeholders
    ) || createJiraIssueRes.loading;

  const onSubmit = async () => {
    const jiraConfig = getJiraConfig("New Data Source", formState);
    if (!jiraConfig) {
      console.error("Missing Jira Routing Config!", formState);
      return; //Required
    }
    const jiraIssueRequest = createJiraIssueReq(jiraConfig, formState);
    const jiraIssueResponse = await createJiraIssue(jiraIssueRequest);
    if (!jiraIssueResponse && createJiraIssueRes.error) {
      makeToast("danger", "Failed to submit form!", { autoClose: 3000 });
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isConfirmedChecked = (event: any) => {
    setFormFieldValue("isConfirmed", event?.target?.checked);
  };

  return (
    <>
      <NavigateBackButton />
      <RequestForm style={{ marginBottom: "18rem" }}>
        <FormPanel>
          {!(createJiraIssueRes.isSuccess && createJiraIssueRes.value) && (
            <FormPanelBody>
              <FormHeading level={2}>
                New Data Source or Data Set Request
              </FormHeading>
              <SubText>
                Please don't use this request category for department/division
                level strategic projects.
              </SubText>

              <FormDetails>
                <Flex style={TopMargin}>
                  <Checkbox
                    checked={isConfirmed}
                    onChange={isConfirmedChecked}
                    id="confirmed"
                    indeterminate={false}
                    disabled={false}
                  >
                    <FieldLabel
                      formatLabel={requiredLabel}
                      style={{
                        lineHeight: 0.4,
                      }}
                      label="I confirm that this data isn't currently available in Growth Snowflake."
                    />
                  </Checkbox>
                </Flex>
                <FieldLabel
                  label="Data Type"
                  htmlFor="data-type"
                  formatLabel={requiredLabel}
                >
                  <Select
                    inputId="data-type"
                    isClearable
                    options={DataTypeOptions}
                    onChange={(selected) => {
                      setFormFieldValue("dataType", selected?.value || "");
                    }}
                    value={DataTypeOptions.find(
                      (option) => option.value === dataType,
                    )}
                  />
                </FieldLabel>
                <InputLabel
                  label="Title"
                  htmlFor="title"
                  formatLabel={requiredLabel}
                >
                  <Input
                    id="title"
                    placeholder=""
                    required
                    fullWidth
                    value={title}
                    onChange={(e) => setFormFieldValue("title", e.target.value)}
                  />
                </InputLabel>
                <FieldLabel
                  label="Request Summary"
                  htmlFor="requestSummary"
                  formatLabel={requiredLabel}
                >
                  <Textarea
                    id="requestSummary"
                    placeholder=""
                    fullWidth
                    value={requestSummary}
                    vSize="lg"
                    onChange={(e) =>
                      setFormFieldValue("requestSummary", e.target.value)
                    }
                  />
                </FieldLabel>
                <InputLabel
                  label="Work Impact"
                  htmlFor="work-impact"
                  formatLabel={requiredLabel}
                >
                  <Select
                    inputId="work-impact"
                    isClearable
                    isDisabled={false}
                    isSearchable
                    options={WorkImpactOptions}
                    fluid
                    onChange={(selectedOption) => {
                      setFormFieldValue("workImpact", selectedOption?.value);
                    }}
                    value={WorkImpactOptions.find(
                      (o) => o.value === workImpact,
                    )}
                  />
                </InputLabel>
                <InputLabel
                  label="Key Stakeholders"
                  htmlFor="key-stakeholders"
                  formatLabel={requiredLabel}
                >
                  <Input
                    id="key-stakeholders"
                    placeholder=""
                    required
                    fullWidth
                    value={stakeholders}
                    onChange={(e) =>
                      setFormFieldValue("stakeholders", e.target.value)
                    }
                  />
                </InputLabel>
                <SubmitButton
                  variant="primary"
                  intent="info"
                  disabled={submitDisabled}
                  onClick={onSubmit}
                >
                  Submit Request
                </SubmitButton>
              </FormDetails>
            </FormPanelBody>
          )}
          {createJiraIssueRes.isSuccess && !!createJiraIssueRes.value && (
            <ThankYou
              textVariation="GEGeneric"
              ticketKey={createJiraIssueRes.value.key}
            />
          )}
        </FormPanel>
      </RequestForm>
    </>
  );
};
